export enum VARIANT_TYPE {
  SUBSCRIPTION = 'subscription',
  SUBSCRIPTION_ADDON = 'subscriptionAddon',
  SINGLE = 'single'
}

export enum VARIANT_PERIOD_UNIT {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year'
}

export enum VARIANT_PRICE_MODEL {
  PER_UNIT = 'perUnit',
  FIXED = 'fixed'
}

export enum VARIANT_STATUS {
  LIVE = 'live',
  DISABLED = 'disabled'
}

export interface Variant {
  id: number
  variantType: VARIANT_TYPE
  enabled: boolean
  status: VARIANT_STATUS,
  shippable: boolean
  externalRefPrice: string
  title: string
  variantCustomTitle: string
  variantAmount: number
  variantPeriod: number
  variantPeriodUnit: VARIANT_PERIOD_UNIT
  variantRefGroup: string
  variantPriceModel: VARIANT_PRICE_MODEL
  dateCreated: string
  dateUpdated: string
  // Multiselect only
  $isDisabled?: boolean
  dosageCycleEnabled: boolean
  dosageCycle: VariantDosageCycle
}

export interface VariantDosageCycle {
  cycleNumber: number,
  unitsDose: string,
  directions: string,
}
